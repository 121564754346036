.section_imobiliaria{
    display: block;
    width: 100%;

    & h1{
        font-size: 2rem;
        font-weight: 600;
        color: $colorTerciary;
    }

    & h2{
        font-size: 2rem;
        font-weight: 600;
        color: $colorTerciary;
    }

    & p{
        font-size: .9rem;
        font-weight: 400;
        color: black;
    }

    & .section_equipe{
        display: block;
        width: 100%;
    
        & .miniatura_equipe{
            display: block;
            width: 100%;
            padding: .8rem;
            border: solid 1px $gray4;
            
            & .img{
                background-color: $colorTerciary;
                display: block;
                width: 100%;
                text-align: center;
                padding: 1rem 0;
                & img{
                    width: 80%;
                    border-radius: 50%;
                    border: solid 5px $gray1;
                    box-shadow: 0 0 10px 1px rgba(black, .5);
                }
            }
    
            & .detalhes{
                display: block;
                width: 100%;
    
                & .nome{
                    font-size: 1rem;
                    font-weight: 600;
                    color: $colorTerciary;
                    text-transform: uppercase;
                }
        
                & p{
                    font-size: .8rem;
                    font-weight: 400;
                    color: $gray2;  
                }

                & a{
                    width: fit-content;
                    display: block;
                    & p{
                        transition: all .2s;
                    }
                    &:hover{
                        & p{
                            color: $colorTerciary;
                        }
                    }
                }
            }
        }
    }
}