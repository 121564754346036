.headerindex {
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	
	& .busca {
		display: block;
		width: 70%;
		text-align: center;
		z-index: 2;
	
		& h1{
			color: $gray1;
			font-size: 2rem;
			font-weight: 600;
			text-align: center;
			margin-bottom: 1rem;
		}
	
		& .busca-filtro{
			width: 100%;
			display: block;
			& .bloco_busca{
				padding: 0;
				margin: 0;
				text-align: center;
				display: block;
				width: 100%;
				& .filtros_busca_home{
					width: 25%;
					display: inline-block;
					padding: .5rem 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
				& .filtros_busca_home2{
					width: 25%;
					display: inline-block;
					padding: .5rem 0;
					& p{
						font-weight: 600;
						margin: 0;
					}
				}
	
				& .filtros_busca_home_input{
					width: 25%;
					display: inline-block;
					padding: 0;
				}
	
				& .padding_right_pc_mob{
					padding-right: 1rem !important;
				}
	
				& .btn-home{
					padding: .8rem 0;
					border-radius: .2rem;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorTerciary;
					color: white;
					font-size: 1rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					transition: all .2s ease-in-out;
					&:hover{
						background-color: darken($colorTerciary, 5);
					}
					
				}
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .75rem 0;
	border: solid 1px $colorTerciary;
	border-radius: .2rem;
	background-color: $colorTerciary;
	color: white;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	cursor: pointer;
	transition: all .2s ease-in-out;
	&:hover{
		background-color: darken($colorTerciary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .75rem 0;
	border: solid 1px $gray4;
	border-radius: .2rem;
	text-transform: uppercase;
	background-color: white;
	color: $colorTerciary;
	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;
	&:hover{
		background-color: $gray5;
	}
}

.btn_filtros_avancados{
	background-color: $colorTerciary;
	display: inline-block;
	vertical-align: middle;
	width: fit-content;
	transition: all .2s ease-in-out;
	cursor: pointer;
	border: solid 1px $colorTerciary;

	& p{
		color: white;
		font-size: .8rem;
		font-weight: 600;
		padding: .5rem 1rem;
		line-height: normal;
		text-transform: uppercase;
		text-align: center;
		margin: 0;
	}
	&:hover{
		background-color: darken($colorTerciary, 5);
	}
}

.btn_limpar_filtros{
	background-color: white;
	display: inline-block;
	vertical-align: middle;
	width: fit-content;
	transition: all .2s ease-in-out;
	cursor: pointer;

	& p{
		color: $gray1;
		font-size: .8rem;
		font-weight: 600;
		padding: .5rem 1rem;
		line-height: normal;
		text-transform: uppercase;
		text-align: center;
		margin: 0;

		& i {
			color: $colorTerciary;
		}
	}
	&:hover{
		background-color: $gray5;
	}
}

@media all and (max-width: 1023px){

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	.headerindex {
		display: block;
		padding: 0;
		@include border-box;
		z-index: 999;
		border-top: solid 1px $gray5;
		
		& .busca {
			background-color: transparent;
			display: block;
			width: 100%;
			padding: 0;
			text-align: center;
			border: none;
			box-shadow: none;

			& h1{
				color: $gray1;
				font-size: 1.5rem;
				font-weight: 600;
				text-align: center;
				margin-bottom: 1rem;
			}
	
			& .busca-filtro{
				width: 100%;
				display: block;
				& .bloco_busca{
					padding: 0;
					margin: 0;
					text-align: center;
					display: block;
					width: 100%;
					& .filtros_busca_home{
						width: 100%;
						display: inline-block;
						padding: 0 0 .5rem 0 !important;
						& p{
							font-weight: 600;
							margin: 0;
						}
					}
					& .filtros_busca_home2{
						width: 100%;
						display: inline-block;
						padding: 0 0 .5rem 0 !important;
						& p{
							font-weight: 600;
							margin: 0;
						}
					}
					& .filtros_busca_home_input{
						width: 50%;
						display: inline-block;
						padding: 0 0 .5rem 0;
					}
	
					& .padding_right_pc_mob{
						padding-right: 0 !important;
					}

					& .btn-home{
						padding: 1rem 0;
						border-radius: .2rem;
						width: 100%;
						display: inline-block;
						vertical-align: middle;
						background-color: $colorTerciary;
						color: white;
						font-size: 1rem;
						font-weight: 600;
						border: none;
						cursor: pointer;
						&:hover{
							background-color: darken($colorTerciary, 5);
						}
						
					}
				}
			}
		}
	}
}

