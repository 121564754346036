.imoveis_semelhantes{
	display: block;
	width: 100%;
	background-color: white;
	border: solid 1px $gray5;
	box-shadow: 0 0 10px 0 rgba($gray4, .5);
	padding: 1.5rem;
	border-radius: .2rem;

	& h4{
		font-size: 1.1rem;
		text-align: left;
		color: $gray1;
		font-weight: 600;
		margin: 0;
	}

	& .container_miniaturas_semelhantes {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;

		& .miniatura_interna {
			background-color: white;
			width: 100%;
			display: block;
			padding: .7rem;
			border: solid 1px lighten($gray4, 10);
			position: relative;
			border-radius: .2rem;

			& a {
				display: block;
				width: 100%;

				& .bloco_img {
					width: 40%;
					display: inline-block;
					vertical-align: top;
					margin: 0;

					& .miniatura_imovel_img {
						display: block;
						width: 100%;
						height: 7rem;
						overflow: hidden;
						background-color: $gray5;
						position: relative;
						user-select: none;
						border-radius: .2rem;

						& img {
							display: block;
							width: 100%;
							height: inherit;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							border-radius: .2rem;
							object-fit: cover;
						}
					}
				}

				& .bloco_informacoes {
					width: 60%;
					display: inline-block;
					vertical-align: top;
					margin: 0;
					padding-left: 1rem;

					& .miniatura_informacoes {
						display: block;
						width: 100%;

						& .miniatura_endereco {
							display: block;
							width: 100%;

							& p {
								font-size: .8rem;
								font-weight: 500;
								color: $gray1;
								margin: 0;

								& i {
									font-size: .7rem;
									font-weight: 600;
									color: $colorTerciary;
								}
							}
						}

						& .codigo_imovel {
							width: 100%;
							display: block;

							& p {
								font-size: .7rem;
								font-weight: 400;
								color: $gray1;
							}

							& .miniatura_acao {
								font-size: .7rem;
								font-weight: 400;
								margin: .25rem 0;
								color: $gray2;
								background-color: transparent;
							}
						}

						& .miniatura_preco {
							display: block;
							width: 100%;

							& p {
								width: 100%;
								text-align: left;
								font-size: .9rem;
								font-weight: 500;
								color: $gray1;
								line-height: none;
								margin: 0;
							}

							& .tt {
								font-size: .75rem;
								color: $gray2;
								font-weight: 400;
								margin: .5rem 0 0 0;
							}
						}
					}
				}

				& .miniatura-imovel-btns-iconsX {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-around;
					height: 4rem;
					width: 100%;
					background-color: lighten($gray5, 2);
					border-radius: .2rem;

					& ul {
						& li {
							font-size: .8rem;
							color: $gray2;
							font-weight: 400;

							& i {
								color: $colorTerciary;
								font-weight: 500;
								font-size: 1rem;
							}
						}
					}
				}
			}
		}
	}

	& .btn_vermais_interna{
		display: block;
		width: fit-content;
		margin: 0;
		& a{
			& p{
				font-size: .8rem;
				font-weight: 600;
				color: $gray2;
				background-color: white;
				border-radius: .2rem;
				border: solid 1px $gray4;
				padding: .3rem 1rem;
				text-align: center;
				transition: all .2s ease-in-out;
				margin-bottom: 0;

				& i {
					color: $colorTerciary;
					transition: all .2s ease-in-out;
				}

				&:hover{
					background-color: $colorTerciary;
					border: solid 1px $colorTerciary;
					color: white;

					& i {
						color: white;
					}
				}
			}
		}
	}
}


@media (min-width: 1024px) and (max-width: 1366px){
	.imoveis_semelhantes {
		& .container_miniaturas_semelhantes {
			& .miniatura_interna {
				& a {
					& .bloco_informacoes {
						& .miniatura_informacoes {
							& .miniatura_titulo {
								& p {
									font-size: .8rem;
								}
							}

							& .miniatura_endereco {
								& p {
									font-size: .75rem;
									margin: .2rem 0 0 0;

									& i {
										font-size: .7rem;
									}
								}
							}

							& .codigo_imovel {
								& p {
									font-size: .75rem;
								}

								& .miniatura_acao {
									font-size: .75rem;
									margin: .2rem 0 0 0;
								}
							}

							& .miniatura_preco {
								& p {
									margin: .2rem 0 0 0;
								}
							}
						}
					}
				}
			}
		}
	}
	
}

@media (max-width: 1023px){
	.imoveis_semelhantes {
		display: block;
		width: 100%;
		background-color: white;
		border: solid 1px $gray5;
		box-shadow: 0 0 10px 0 rgba($gray4, .5);
		padding: 1rem;
		border-radius: .2rem;

		& .container_miniaturas_semelhantes {

			& .miniatura_interna {
				border: solid 1px lighten($gray4, 10);

				& a {
					& .bloco_img {
						width: 100%;
						display: block;
						margin: 0;

						& .miniatura_imovel_img {
							height: 13rem;
						}
					}

					& .bloco_informacoes {
						width: 100%;
						margin: 1rem 0 0 0;
						padding-left: 0;
						padding: 0 .5rem;

						& .miniatura_informacoes {
							& .miniatura_titulo {
								& p {
									font-size: 1rem;
								}
							}

							& .miniatura_endereco {
								& p {
									font-size: .9rem;
									margin: .5rem 0;
								}
							}

							& .codigo_imovel {
								& .miniatura_acao {
									font-size: .9rem;
								}
							}

							& .miniatura_preco {
								display: block;
								width: 100%;

								& .tt {
									font-size: .9rem;
									color: $gray2;
									font-weight: 400;
									margin: 0;
								}

								& p {
									width: 100%;
									text-align: left;
									font-size: 1.2rem;
									font-weight: 600;
									color: $gray1;
									line-height: none;
									margin: 0;
								}
							}
						}
					}

					& .miniatura-imovel-btns-iconsX {
						height: 4.5rem;
						gap: .5rem;
					}
				}
			}
		}
	}
}