.text_descricao_imo{
	display: block;
	width: 100%;

	& h2{
		color: $colorTerciary;
		font-size: 1.1rem;
		font-weight: 600;
	}

	& p{
		font-size: .9rem;
	}

	& .informacoes_tens{
		color: $gray2;
		font-size: .8rem;

		& i{
			font-size: 1.5rem;
		}
	}
}

.imo_title{
	display: block;
	width: 100%;

	& h1{
		font-size: 1.5rem;
		font-weight: 500;
		color: $gray1;
	}

	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray2;
	}
}

.galeriaa_right{
	width: fit-content;
	display: block;
	margin: 0;

	& .acao_tipo{
		font-size: 1rem;
		color: $gray1;
		text-align: center;
		font-weight: 600;
		margin: 0;
		padding: .5rem 1.5rem;
		border: solid 1px $gray4;
		border-radius: .2rem;
	}
}

.box_aceitatroca{
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	border-radius: .2rem;
	padding: .5rem 1rem;
	margin: 0;
	
	& .title{
		font-size: .9rem;
		font-weight: 600;
		color: $gray1;
		margin: 0;
	}

	& p{
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;
		margin: 0;
	}
}

.box_promocao_interna{
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	border-radius: .2rem;
	padding: .5rem 1rem;
	margin: 0;
	
	& .title{
		font-size: .9rem;
		font-weight: 600;
		color: $gray1;
		margin: 0;
	}

	& p{
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;
		margin: 0;
	}
}

.info_valores_mob{
	width: 100%;
	display: block;

	& .acao_tipo{
		font-size: .9rem;
		color: $gray2;
		font-weight: 400;
		margin: 0;
	}

	& .tarja_valor{
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: .5rem;
		padding-bottom: 1rem;
		border-bottom: solid 1px lighten($gray4, 10);

		& p {
			font-size: 2rem;
			color: $colorTerciary;
			font-weight: 600;
			margin: 0;
		}

		& span {
			margin: 0;
			background-color: lighten($gray4, 15);
			padding: .25rem 1rem;
			border-radius: .2rem;
			color: $gray1;
			font-size: .9rem;
			font-weight: 500;
			width: fit-content;
		}
	}

	& .boxValores {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;

		& .valor_imovel{
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;

			& .titlevalor{
				font-size: .9rem;
				font-weight: 500;
				color: $gray2;
				margin: 0;

				& i{
					font-size: .8rem;
					font-weight: 500;
					color: $colorTerciary;
					cursor: pointer;
				}
			}
			
			& p{
				font-size: .95rem;
				font-weight: 500;
				color: $gray1;
				margin: .2rem 0;
			}
		}


		& .outrosValoresImovel {
			display: flex;
			flex-direction: column-reverse;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1rem;
			margin: 1rem 0;
			width: 100%;

			& .valores{
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;
	
				& .title{
					font-size: .9rem;
					font-weight: 500;
					color: $gray2;
					margin: 0;
	
					& i{
						font-size: .8rem;
						font-weight: 500;
						color: $colorTerciary;
						cursor: pointer;
					}
				}
				
				& p{
					font-size: .95rem;
					font-weight: 500;
					color: $gray1;
					margin: .2rem 0;
				}
			}
		}
	}

	& .btn_entre_em_contato{
		background-color: $colorTerciary;
		text-align: center;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		padding: .3rem 0;
		border-radius: 2rem;
		cursor: pointer;
		transition: all .2s ease-in-out;
		margin: 0;
		&:hover{
			background-color: $colorSecondary;
		}
	}
}

.formulario_interno{
	background-color: white;
	padding: 1.5rem;
	border-radius: .2rem;
	box-shadow: 0 0 10px 0 rgba($gray4, .5);

	& h4{
		font-size: 1.5rem;
		font-weight: 600;
		color: $gray1;
		text-align: center;
		margin: 0;
	}

	& #form_pag_interna{
   	 	padding: 1rem 0 0 0;
	}

	& .btn_zap_interno{
		width: fit-content;
		display: block;
		margin: auto;

		& p{
			text-align: center;
			color: white;
			margin: 0;
			padding: .3rem 2rem;
			font-size: 1.1rem;
			font-weight: 600;
			vertical-align: middle;
			background-color: #25d366;
			border-radius: 2rem;
			transition: all .2s ease-in-out;

			& i{
				vertical-align: middle;
				font-size: 1.6rem;
				font-weight: 400;
			}
			&:hover{
				background-color: darken(#25d366, 5);
			}
		}
	}

	& .corretor_img{
		display: inline-block;
		vertical-align: middle;
		width: 20%;
		& img{
			width: 100%;
			border-radius: 50%;
		}
	}

	& .corretor_dados{
		display: inline-block;
		vertical-align: middle;
		width: 80%;
		padding: 0 .5rem 0 0;
		& p{
			margin: .2rem 0;
			font-size: .9rem;
			font-weight: 500;
			color: $gray2;
		}
	}

	& .info_valores{
		width: 100%;
		display: block;

		& .acao_tipo{
			font-size: .9rem;
			color: $gray2;
			font-weight: 400;
			margin: 0;
		}
	
		& .tarja_valor{
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: .5rem;
			padding-bottom: 1rem;
			border-bottom: solid 1px lighten($gray4, 10);

			& p {
				font-size: 2rem;
				color: $colorTerciary;
				font-weight: 600;
				margin: 0;
			}

			& span {
				margin: 0;
				background-color: lighten($gray4, 15);
				padding: .25rem 1rem;
				border-radius: .2rem;
				color: $gray1;
				font-size: .9rem;
				font-weight: 500;
				width: fit-content;
				line-height: 1.5rem;
			}
		}

		& .boxValores {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			width: 100%;

			& .valor_imovel{
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;
	
				& .titlevalor{
					font-size: .9rem;
					font-weight: 500;
					color: $gray2;
					margin: 0;
	
					& i{
						font-size: .8rem;
						font-weight: 500;
						color: $colorTerciary;
						cursor: pointer;
					}
				}
				
				& p{
					font-size: .95rem;
					font-weight: 500;
					color: $gray1;
					margin: .2rem 0;
				}
			}


			& .outrosValoresImovel {
				display: flex;
				flex-direction: column-reverse;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 1rem;
				margin: 1rem 0;
				width: 100%;
	
				& .valores{
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					box-sizing: border-box;
		
					& .title{
						font-size: .9rem;
						font-weight: 500;
						color: $gray2;
						margin: 0;
		
						& i{
							font-size: .8rem;
							font-weight: 500;
							color: $colorTerciary;
							cursor: pointer;
						}
					}
					
					& p{
						font-size: .95rem;
						font-weight: 500;
						color: $gray1;
						margin: .2rem 0;
					}
				}
			}
		}

	}

	& .btn_entre_em_contato{
		background-color: $colorTerciary;
		text-align: center;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		padding: .3rem 0;
		border-radius: .2rem;
		border: solid 1px $colorTerciary;
		cursor: pointer;
		transition: all .2s ease-in-out;
		margin: 0;

		&:hover{
			background-color: darken($colorTerciary, 5);
		}
	}

	& .btn_entre_em_contato2{
		width: fit-content;
		background-color: white;
		text-align: center;
		color: $gray2;
		border: solid 1px $gray4;
		font-size: .8rem;
		font-weight: 600;
		padding: .3rem 1rem;
		border-radius: .2rem;
		cursor: pointer;
		transition: all .2s ease-in-out;
		margin: 0;

		& i {
			color: $colorTerciary;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: $colorTerciary;
			border: solid 1px $colorTerciary;
			color: white;

			& i {
				color: white;
			}
		}
	}
	
}

.texto_descricao_imo{
	max-width: 55rem;
	margin-left: 0;

	& p{
		color: $gray2;
		font-size: .9rem;
		font-weight: 400;
		line-height: 1.5rem;
	}
}

.text_align_pc_mob{
	text-align: right;
}

.imo_codigo_interna{
	width: fit-content;
	padding: 0 1rem;
	display: inline-block;
	vertical-align: middle;
	
	& p{
		background-color: white;
		font-size: .9rem;
		color: $gray1;
		border: solid 1px $gray4;
		border-radius: .2rem;
		width: fit-content;
		font-weight: 500;
		margin: 0;
		text-align: center;
		padding: .5rem 1rem;
		
		& span{
			font-size: 1rem;
			color: $gray1;
			font-weight: 500;
		}
	}
}

.share_session{
	background-color: white;
	border-radius: 0 .2rem .2rem 0;
	border-top: solid 1px $gray4;
	border-right: solid 1px $gray4;
	border-bottom: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	width: fit-content;
	padding: 0 1rem;
	& .item2{
		display: block;
		text-align: center;
		width: 100%;

		& p{
			padding: 0;
			margin: 0;
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: .9rem;
			font-weight: 600;
		}

		& i{
			display: inline-block;
			vertical-align: middle;
			font-size: 1.1rem !important;
		}
	}
}

.imprimir_session{
	background-color: white;
	border: solid 1px $gray4;
	display: inline-block;
	vertical-align: middle;
	border-radius: .2rem 0 0 .2rem;
	width: fit-content;
	padding: 0 1rem;
	
	& .item{
		display: block;
		text-align: center;
		width: 100%;
		& p{
			margin: 0;
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: .9rem;
			font-weight: 600;
		}

		& i{
			display: inline-block;
			vertical-align: middle;
			font-size: 1.1rem !important;
		}
	}
}

.btn_entrar_em_contato_mobile {
	display: block;
	width: 100%;

	& button {
		width: 100%;
		padding: .7rem 0;
		border: solid 1px $colorTerciary;
		background-color: $colorTerciary;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: .3rem;

		& i {
			font-weight: 600;
		}

		&:active {
			background-color: lighten($colorTerciary, 5);
		}
	}
}

.dialogValoresImovelInterna {
	background-color: white;
	padding: 1rem 2rem;
	border-radius: .2rem;
	width: 30vw;
	max-width: 30vw;
	height: 30vh;
	max-height: 30vh;
	overflow: auto;
	box-sizing: border-box;
	
	& h4 {
		font-size: 1.25rem;
		font-weight: 600;
		margin: 0;
		color: $gray1;
		text-align: center;
		border-bottom: solid 1px lighten($gray4, 10);
		padding-bottom: .5rem;
	}

	& p {
		font-size: .9rem;
		color: $gray2;
		font-weight: 400;
		word-break: break-all;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.imo_title{
		display: block;
		width: 100%;
	
		& h1{
			font-size: 1.1rem;
			font-weight: 500;
			color: $gray1;
			margin: 0;
		}
	
		& p{
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
		}
	}

	.formulario_interno{
		padding: 1.25rem;

		& .info_valores{
			& .acao_tipo{
				font-size: .8rem;
			}
		
			& .tarja_valor{
				& p {
					font-size: 1.8rem;
				}
	
				& span {
					padding: .25rem .75em;
					font-size: .8rem;
				}
			}
	
			& .boxValores {
				& .valor_imovel{
					& .titlevalor{
						font-size: .85rem;
		
						& i{
							font-size: .7rem;
						}
					}
					
					& p{
						font-size: .85rem;
					}
				}
	
	
				& .outrosValoresImovel {
					& .valores{
						& .title{
							font-size: .85rem;
			
							& i{
								font-size: .8rem;
								vertical-align: text-bottom;
							}
						}
						
						& p{
							font-size: .85rem;
						}
					}
				}
			}
		}
	}
}

/* MOBILE */
@media (max-width: 1023px){

	.dialogValoresImovelInterna {
		width: 80vw;
		max-width: 80vw;
		height: 40vh;
		max-height: 40vh;
		
		& h4 {
			font-size: 1.1rem;
		}
	}

	.imo_title{
		& h1{
			font-size: 1rem;
		}
		& p{
			font-size: .9rem;
		}
	}

	.galeriaa_right{
		width: 100%;
		display: block;
	}

	.info_valores_mob{
		& .tarja_valor{
			& p {
				font-size: 1.8rem;
			}

			& span {
				font-size: .8rem;
			}
		}

		& .boxValores {
			& .valor_imovel{
				& .titlevalor{
					font-size: .85rem;
				}
				
				& p{
					font-size: .85rem;
				}
			}

			& .outrosValoresImovel {
				& .valores{
					& .title{
						font-size: .85rem;
		
						& i{
							font-size: .85rem;
						}
					}
					
					& p{
						font-size: .85rem;
					}
				}
			}
		}
	}

	.formulario_interno{
		padding: 1.25rem;

		& .info_valores{
			& .acao_tipo{
				font-size: .8rem;
			}
		
			& .tarja_valor{
				& p {
					font-size: 1.8rem;
				}
	
				& span {
					padding: .25rem .75em;
					font-size: .8rem;
				}
			}
	
			& .boxValores {
				& .valor_imovel{
					& .titlevalor{
						font-size: .85rem;
		
						& i{
							font-size: .7rem;
						}
					}
					
					& p{
						font-size: .85rem;
					}
				}
	
	
				& .outrosValoresImovel {
					& .valores{
						& .title{
							font-size: .85rem;
			
							& i{
								font-size: .8rem;
								vertical-align: text-bottom;
							}
						}
						
						& p{
							font-size: .85rem;
						}
					}
				}
			}
		}
	}

	.texto_descricao_imo{
		width: 100%;
		line-height: 1.3rem;
		font-size: 1rem;
	}

	.text_align_pc_mob{
		text-align: center;
	}

	.imo_codigo_interna{
		width: 40%;
		padding: 0 1rem 0 0;
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		
		& p{
			background-color: white;
			font-size: .9rem;
			color: $gray1;
			border: solid 1px $gray4;
			border-radius: .2rem;
			width: 100%;
			font-weight: 500;
			margin: 0;
			text-align: center;
			padding: .5rem 0;
			
			& span{
				font-size: 1rem;
				color: $gray1;
				font-weight: 500;
			}
		}
	}

	.share_session{
		background-color: white;
		border-radius: .2rem;
		border: solid 1px $gray4;
		display: inline-block;
		vertical-align: middle;
		width: 60%;

		& .item2{
			display: block;
			text-align: center;
			width: 100%;
	
			& p{
				padding: 0;
				display: inline-block;
				color: $gray1;
				font-size: .9rem;
				font-weight: 400;
			}
	
			& i{
				display: inline-block;
				vertical-align: middle;
				font-size: 1.3rem !important;
				margin: 0;
			}
		}
	}
}