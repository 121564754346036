/* MINIATURA 1 */
.cr {
	width: 100%;
	padding: .3rem;
	position: absolute;
	text-align: center;
	color: #f0f0f0;
}
  
.cr-sticky {
position: absolute;
}
  
/* Positions */

.cr-top    { top: 30px; }
.cr-bottom { bottom: 25px; }
.cr-left   { left: -120px; }
.cr-right  { right: -35px; }

/* Rotations */

.cr-top.cr-left,
.cr-bottom.cr-right {
	transform: rotate(-45deg);
}

.cr-top.cr-right,
.cr-bottom.cr-left {
	transform: rotate(45deg);
}
  
/* Colors */
.cr-primary{
	background-color: rgba($colorTerciary, .5);
	font-weight: 500;
	color: $colorPrimary;
	font-size: .75rem;
}

.miniatura-imovel {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;
	position: relative;

	& .miniatura-imovel-img{
		display: block;
		border-radius: .2rem .2rem 0 0;
		width: 100%;
		background-color: $gray4;
		user-select: none;
		border: solid 1px $gray4;
		border-bottom: none;
		position: relative;
		overflow: hidden;

		& img{
			display: block;
			width: 100%;
			border-radius: .2rem .2rem 0 0;
		}

		& .miniatura_status{
			position: absolute;
			top: .5rem;
			left: .5rem;
			& p{
				background-color: rgba($colorTerciary, .9);
				font-weight: 600;
				color: white;
				font-size: .75rem;
				padding: 0 1rem;
				border-radius: .2rem;
				margin: 0;
				line-height: normal;
			}
		}

		& .miniatura-cod{
			display: block;
			width: fit-content;
			position: absolute;
			top: .5rem;
			right: .5rem;
			& p{
				background-color: rgba($colorTerciary, .9);
				font-size: .75rem;
				font-weight: 600;
				text-align: center;
				color: white;
				padding: 0 1rem;
				border-radius: .2rem;
				margin: 0;
				line-height: normal;
			}
		}

		& .tarja_vendido_miniatura{
			position: absolute;
			top: 45%;
			left: -25%;
			transform: translate(25%, -45%);
			width: 150%;
			text-align: center;
			background-color: rgba($colorTerciary, .9);
			color: white;
			font-size: 1.5rem;
			padding: .5rem 0;
			text-transform: uppercase;
			transform: rotate(335deg);
		}
	}

	& .miniatura-imovel-detalhe{
		display: block;
		border-left: solid 1px $gray4;
		border-right: solid 1px $gray4;
		width: 100%;
		padding: 1rem 1rem 0 1rem;
		background-color: white;
		@include border-box;
		position: relative;

		& .miniatura-titulo{
			display: block;
			width: 100%;

			& p{
				font-size: .9rem;
				font-weight: 500;
				color: $gray1;
				margin: 0;

				& i{
					font-size: .8rem;
					font-weight: 400;
					color: $colorTerciary;
				}
			}

			& .aca_tip{
				font-size: .75rem;
				font-weight: 400;
				color: $gray2;
			}
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			height: 3rem;
			
			& ul{
				& li{
					font-size: .9rem;
					color: $gray2;
					font-weight: 400;
				}
			}
		}
	}

	& .miniatura-preco{
		display: block;
		width: 100% !important;
		background-color: lighten($gray5, 3);
		border-left: solid 1px $gray4;
		border-right: solid 1px $gray4;
		border-bottom: solid 1px $gray4;
		border-radius: 0 0 .2rem .2rem;
		cursor: pointer;
		& p{
			padding: 1rem;
			margin: 0;
			text-align: left;
			font-size: 1.1rem;
			font-weight: 600;
			color: $gray1;
		}
		&:hover{
			background-color: lighten($gray5, 1);
		}
	}
}

.splide__arrow--prev{
	left: -2rem !important;
}

.splide__arrow--next{
	right: -2rem !important;
}

/* SÓ SE APLICA NO PC */
@media all and (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}

}

@media all and (min-width: 1201px) and (max-width: 1366px){
	.splide__arrow--prev{
		left: 2rem !important;
	}
	
	.splide__arrow--next{
		right: 2rem !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	.splide__arrow--prev{
		left: 1rem !important;
	}
	
	.splide__arrow--next{
		right: 1rem !important;
	}
}

