.nav {
	display: block;
	width: 50vh;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: white;
	z-index: 99999999;
	padding: 0 1rem;
	box-shadow: 0px 0 20px 1px rgba($gray1, .1);

	& button {
		margin-right: 1rem;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-menu {
		color: $gray1;
		font-size: 1.2rem;
		font-weight: 400;
		margin: 0;
		padding: .5rem 1rem;
		@include border-box;
		border-bottom: solid 2px lighten($gray5, 3);
		transition: all .25s;

		&:hover {
			background-color: $gray5;
			padding-left: 1.5rem;
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.nav {
		width: 30vw;
	}
}

@media all and (max-width: 1023px){
	.nav{
		width: 100%;
		overflow: auto;
		
	}
}