.header1{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}

.box_header{
	z-index: 999;
	position: relative;
}

.header2{
	background-color: white;
	width: 100%;
	z-index: 9999;
	& p{
		color: $gray1 !important;
	}

	& a{
		color: $gray1 !important;
		&:hover{
			color: $colorPrimary !important;
		}
	}
}
	
.menuacao_btn{
	background-color: $colorPrimary !important;
	color: white !important;
	margin: 0%;
}

.header3{
	width: 100%;
	animation: header3pc .45s ease-in-out;
	animation-fill-mode: forwards;
	transform-origin: center center;
	z-index: 99999999;
}

@keyframes header3pc{
	0% {
		//background-image: linear-gradient(to bottom right, #00174900, #03428200);
		background-color: transparent;
	}
	100% {
		//background-image: linear-gradient(to bottom right, rgba($colorPrimary, 1), rgba($colorSecondary, 1));
		background-color: $colorPrimary;
	}
}

.headerinterno {
	height: 5rem;
	z-index: 1;
}
.busca-top{
	width: 100%;
	background-color: $colorPrimary;
	height: 2rem;
}

#sub_menu_vendas{
	display: none;
	width: 230px;
	max-width: 230px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	left: 50%;
	top: 2.4rem;
	transform: translate(-50%);
	background-color: white;
	border: solid 1px $gray4;
	border-radius: .2rem;
	z-index: 3;

	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		padding: .5rem 0;
		& p{
			font-size: .9rem !important;
			font-weight: 400;
			background-color: none;
			padding: 0.5rem 1.5rem;
			margin: 0;
			text-align: left;
			transition: all .2s;
			color: $gray1;
			&:hover{
				background-color: $gray5;
				padding-left: 2rem;
			}
		}
	}
}

.headeer{
	display: block;
	width: 100%;
	box-shadow: 0 0 10px 0 rgba(black, .1);
	border-bottom: solid 1px $gray5;

	& .tarja_top{
		width: 100%;
		display: block;
		padding: .3rem 0;
		& .creci_header{
			width: 50%;
			display: inline-block;
			vertical-align: middle;
			
			& p{
				text-align: left;
				color: $gray2;
				font-size: .8rem;
				font-weight: 400;
				margin: 0;
			}
		}
	
		& .itens_tarja{
			width: 50%;
			display: inline-block;
	
			& ul {
				flex: 0 0 100%;
				display: flex;
				align-items: center;
				margin: 0%;
				padding: 0%;
				list-style: none;
				justify-content: flex-end;
				transition: all .25s ease;
				will-change: height;
				
				& .ith{
					display: inline-block;
					padding: 0 20px;
	
					& a{
						justify-content: space-between;
						margin: 0;
						color: $gray1;
						font-weight: 400;
						font-size: .8rem;
						transition: all .2s ease-in-out;
	
						& i{
							font-size: 1rem;
							vertical-align: middle;
							color: $colorTerciary;
							transition: all .2s ease-in-out;
						}
	
						&:hover{
							color: $colorTerciary;
						}
					}
				}
			}
		}
	}
	
	& .headerpc {
		display: block;
		width: 100%;
		position: relative;
	
		& .superior {
			display: block;
			width: 100%;
			color: #ffffff;
			z-index: 3;
			
			& .logo {
				display: inline-block;
				margin-left: 0;
				@include border-box;
				vertical-align: middle;
				width: 6%;
				padding: .5rem 0;
	
				& img {
					display: block;
					width: 100%;
				}
			}
	
			/* GRUPO HEADER */
			& .menu {
				text-align: right;
				display: inline-block;
				vertical-align: middle;
				width: 5%;
			}
	
			& .sessions {
				display: inline-block;
				width: 89%;
				vertical-align: middle;
	
				& ul {
					flex: 0 0 0 100%;
					display: flex;
					align-items: center;
					margin: 0%;
					padding: 0%;
					list-style: none;
					justify-content: flex-end;
					transition: all .25s ease;
					will-change: height;
	
					& .itens_header{
						display: inline-block;
						padding: 0 20px;
						
						& a{
							justify-content: space-between;
							color: $gray1;
							font-weight: 400;
							font-size: .9rem;
							transition: all .2s ease-in-out;
							&:hover{
								color: $colorTerciary;
							}
						}

						& .itemsession{
							position: relative;
							display: inline-block;
							vertical-align: middle;
							width: 100%;
		
							& .title_is{
								width: 100%;
								display: block;
		
								& .itemsession_title{
									vertical-align: middle;
									display: inline-block;
									width: 100%;
									font-size: .9rem;
									color:  $gray1;
									text-align: center;
									text-transform: capitalize;
									font-weight: 400;
									padding: 0;
									@include border-box;
									transition: all .15s ease-in-out;
									
									& i{
										vertical-align: middle;
										display: inline-block;
										font-size: .65rem;
										color:  $colorTerciary;
										font-weight: 300;
									}
								}
							}
		
							&:hover{
								#sub_menu_vendas{
									display: initial;
								}
							}
						}
					}
				}
			}
		}
	}
}

.header-mob{
	background-color: $colorSecondary;
	& .log{
		display: inline-block;
		vertical-align: middle;
		width: 23%;
		& img{
			width: 100%;
		}
	}

	& .btn-men{
		width: 77%;
		display: inline-block;
		vertical-align: middle;
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.headerpc {
		display: block;
		width: 100%;
		position: relative;
	
		& .superior {
			display: block;
			width: 100%;
			color: #ffffff;
			z-index: 3;
			
			& .logo {
				display: inline-block;
				margin-left: 0;
				@include border-box;
				vertical-align: middle;
				width: 5%;
				padding: .5rem 0;
	
				& img {
					display: block;
					width: 100%;
				}
			}
	
			/* GRUPO HEADER */
			& .menu {
				text-align: right;
				display: inline-block;
				vertical-align: middle;
				width: 5%;
			}
	
			& .sessions {
				display: inline-block;
				width: 90%;
				vertical-align: middle;
	
				& ul {
					flex: 0 0 0 100%;
					display: flex;
					align-items: center;
					margin: 0%;
					padding: 0%;
					list-style: none;
					justify-content: flex-end;
					transition: all .25s ease;
					will-change: height;
	
					& .itens_header{
						display: inline-block;
						padding: 0 20px;
						
						& a{
							justify-content: space-between;
							color: $gray1;
							font-weight: 400;
							font-size: .85rem;
							transition: all .2s ease-in-out;
							&:hover{
								color: $colorTerciary;
							}
						}
						& .itemsession{
							position: relative;
							display: inline-block;
							vertical-align: middle;
							width: 100%;
		
							& .title_is{
								width: 100%;
								display: block;
		
								& .itemsession_title{
									vertical-align: middle;
									display: inline-block;
									width: 100%;
									font-size: .85rem;
									color:  $gray1;
									text-align: center;
									text-transform: capitalize;
									font-weight: 400;
									padding: 0;
									@include border-box;
									transition: all .15s ease-in-out;
									
									& i{
										vertical-align: middle;
										display: inline-block;
										font-size: .65rem;
										color:  $colorPrimary;
										font-weight: 300;
									}
								}
							}
		
							&:hover{
								#sub_menu_vendas{
									display: initial;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	
	.box_header{
		z-index: 999;
		position: relative;
		border-bottom: solid 1px $gray5;
		box-shadow: 0 0 10px 0 $gray5;
	}

	.headerpc{
		
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}
