.footer1{
	border-top: solid 2px lighten($gray4, 10);
	width: 100%;
	display: block;
	background-color: white;

	& .logo_footer{
		display: inline-block;
		vertical-align: top;
		width: 100%;

		& a{
			display: block;
			width: fit-content;
			& .img{
				display: block;
				width: 65%;
				margin: 0;
				& img{
					width: 100%;
				}
			}
		}
	}

	& .rs_footer{
		display: block;
		width: 100%;

		& a{
			margin-right: .5rem;
			display: inline-block;
			vertical-align: middle;
			border-right: none;
			padding: 0;
			
			& p{
				border: solid 1px transparent;
				border-radius: .5rem;
				transition: all .2s;
				margin: 0;
				
				& i{
					color: $colorTerciary;
					background-color: white;
					box-shadow: 0 0 15px 0 rgba($gray2, .25);
					border-radius: 50%;
					padding: .8rem;
					transition: all .2s ease-in-out;
					font-size: 1rem;
				}
			}

			&:hover{
				& p{
					& i{
						color: $colorPrimary;
					}
				}
				border-right: none;
				padding-right: none;
			}
		}
	}

	& .contatofooter{
		display: block;
		width: 100%;
		
		& .tel_email_footer{
			display: inline-block;
			width: 100%;
			vertical-align: top;

			& h3{
				font-size: .9rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
			}
			
			& a{
				display: block;
				width: fit-content;
				& p{
					font-size: .85rem;
					font-weight: 400;
					color: $gray1;
					transition: all .2s;
					&:hover{
						color: $colorPrimary;
					}
				}
			}
		}
	}
	
	& .menu_footer{
		display: block;
		width: 100%;

		& a{
			display: block;
			width: fit-content;
			margin: 0;

			& p{
				font-size: .9rem;
				font-weight: 500;
				color: $gray1;
				border-left: solid 2px transparent;
				transition: all .2s ease-in-out;
				margin-top: 0;

				&:hover{
					padding-left: .5rem;
					border-left: solid 2px $colorPrimary;
				}
			}
		}
	}

	& .endereco_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: .9rem;
			font-weight: 600;
			color: $gray1;
			margin: 0;
			& i{
				color: $colorTerciary;
			}
		}

		& p{
			font-size: .85rem;
			font-weight: 400;
			color: $gray1;
		}
	}

	& .horarioatendimento_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: .9rem;
			font-weight: 600;
			color: $gray1;
			margin: 0;
			& i{
				color: $colorTerciary;
			}
		}

		& p{
			font-size: .85rem;
			font-weight: 400;
			color: $gray1;
		}
	}

	& .copyright_footer{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& p{
			text-align: left;
			color: $gray1;
			font-size: .8rem;
			font-weight: 400;
			display: inline-block;
			vertical-align: middle;
			& strong{
				color: $gray1;
			}
		}
	}

	& .devby{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& .developed{
			display: inline-block;
			vertical-align: middle;
			width: 60%;

			& p{
				text-align: right;
				color: $gray1;
				font-size: .8rem;
				font-weight: 400;
			}
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 15%;

			& .logo{
				width: 4rem;
				& img{
					width: 100%;
				}
			}

			& .logo2{
				width: 7rem;
				& img{
					width: 100%;
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	
	.footer1{
		& .devby{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& .developed{
				display: inline-block;
				width: 50%;
				vertical-align: middle;

				& p{
					text-align: right;
					color: $gray1;
					font-size: .8rem;
					font-weight: 400;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 25%;

				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 8rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.footer1{

		& .logo_footer{
			display: block;
			width: 100%;
	
			& a{
				display: block;
				width: fit-content;
				& .img{
					display: block;
					width: 50%;
					margin: 0;
					margin: auto;
					& img{
						width: 100%;
					}
				}
			}
		}
		
		& .rs_footer{
			display: block;
			width: 100%;
			& a{
				margin-right: .5rem;
				display: inline-block;
				vertical-align: middle;
				border-right: none;
				padding: 0;
				
				& p{
					border: solid 1px transparent;
					border-radius: .5rem;
					transition: all .2s;
					margin: 0;
					
					& i{
						color: $colorTerciary;
						background-color: white;
						box-shadow: 0 0 15px 0 rgba($gray2, .25);
						border-radius: 50%;
						padding: .8rem;
						transition: all .2s ease-in-out;
						font-size: 1rem;
					}
				}
	
				&:hover{
					& p{
						& i{
							color: $colorPrimary;
						}
					}
					border-right: none;
					padding-right: none;
				}
			}
		}

		& .contatofooter{
			display: block;
			width: 100%;
	
			& .tel_email_footer{
				display: block;
				width: 100%;
	
				& h3{
					font-size: 1rem;
					font-weight: 600;
					color: $gray1;
				}
				
				& a{
					display: block;
					width: fit-content;
					& p{
						font-size: .9rem;
						font-weight: 400;
						color: $gray1;
						transition: all .2s;
						&:hover{
							color: $colorPrimary;
						}
					}
				}
			}
		}

		& .copyright_footer{
			display: block;
			width: 100%;
			& p{
				text-align: center;
				color: $gray1;
				font-size: .8rem;
				font-weight: 400;
				& strong{
					color: $gray1;
				}
			}
		}

		& .devby{
			display: block;
			width: 100%;
			& .developed{
				display: block;
				width: 100%;

				& p{
					text-align: center;
					color: $gray1;
					font-size: .8rem;
					font-weight: 400;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 50%;

				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 8rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}