.top_section_imo{
	display: block;
	width: 100%;
	border-bottom: solid 1px lighten($gray4, 10);
	padding: .5rem 1rem;
	& .title_condomi{
		display: block;
		width: 100%;
		
		& h2{
			font-size: 1.5rem;
			font-weight: 500;
			color: $gray1;
			text-align: left;
			margin: 0;
		}
	
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 3px;
			margin: 0;
			background-color: $colorPrimary;
		}
	}
	
	& .btn_ver_mais{
		display: block;
		width: fit-content;
		margin-right: 0;
	
		& a{
			width: fit-content !important;
			display: block;
			
			& p{
				color: $gray1;
				font-weight: 600;
				font-size: .8rem;
				transition: all .2s ease-in-out;
				text-align: center;
				margin: 0;
				border: solid 1px $gray4;
				padding: .5rem 1rem;
				border-radius: .2rem;
				background-color: white;
	
				&:hover{
					background-color: $colorPrimary;
					border: solid 1px $colorPrimary;
					color: white;
				}
			}
		}
	}
}

.title_condomi2{
	text-align: center;
	
	& h2{
		font-size: 1.5rem;
		font-weight: 500;
		color: $gray1;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorTerciary;
	}
}

.blog_section{
	width: 100%;
	display: block;
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
		text-align: center;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.bg_section_servicos{
	display: block;
	width: 100%;
	box-shadow: 0 0 15px 0 rgba($gray2, .1);

	& .section_servicos{
		width: 100%;
		display: block;
		
		& a{
			display: inline-block;
			width: 100%;
			vertical-align: top;
			padding: 1.5rem;
			border-radius: .1rem;
			transition: all .15s;

			& .bloco_servico{
				width: 100%;
				display: block;
				text-align: left;
	
				& .img{
					width: 3rem;
					display: block;
					margin: 0;

					& img{
						transition: all .15s;
						text-align: left;
						width: 100%;
					}
				}

				& h2{
					color: black;
					font-size: 1rem;
					font-weight: 500;
					text-align: left;
				}

				& p{
					padding: 0;
					color: $gray2;
					font-size: .9rem;
					font-weight: 400;
					text-align: left;	
					transition: all .15s;
					margin: 0;
				}
	
				& .underline_x{
					&::after{
						position: absolute;
						content: "";
						width: 1%;
						height: 0;
						background-color: transparent;
						border-radius: 2rem;
						left: 0%;
						bottom: 0;
						transition: all 0.25s ease-in-out;
					}
				}
			}

			&:hover{
				& .img{
					& img{
						transform: scale(1.1);
					}
				}
	
				& .underline_x{
	
					&::after{
						background-color: $colorPrimary;
						height: 100%;
					}
				}
			}
		}
	}
}

.bg_busca_interna{
	background-color: white;
}

.titulo-formulario{
	line-height: normal;
	font-size: .9rem;
	font-weight: 400;
	color: $gray2;
}

.b_submit_email{
	width: 100%;
	border: none;
	padding: .5rem 0;
	background-color: $colorTerciary;
	color: white;
	text-transform: uppercase;
	border-radius: 2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease;
	&:hover{
		background-color: darken($colorTerciary, 2);
	}
}

.botao_voltar{
	background-color: white;
	border: solid 1px $gray4;
	border-radius: .2rem;
	padding: .3rem 1rem;
	color: $gray1;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: $colorPrimary;
		color: black;
	}
}

.botao_voltar_interna{
	background-color: white;
	border: solid 1px $gray4;
	border-radius: .2rem;
	padding: .3rem 1rem;
	color: $gray1;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: $colorPrimary;
		border: solid 1px $colorPrimary;
		color: white;
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 500;
	color: $gray1;
	margin: 0;
	vertical-align: middle;
	& img{
		vertical-align: middle;
		display: inline-block;
		width: 2rem;
	}
}

@media all and (max-width: 1023px){

	.title_condomi{
		text-align: center;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
		}
		& p{
			font-size: 1rem;
			font-weight: 400;
			color: $gray3;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}
	.blog_section{
		width: 100%;
		display: block;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	.bg_section_servicos{
		display: block;
		width: 100%;
		box-shadow: 0 0 15px 0 rgba($gray2, .1);
		& .section_servicos{
			width: 100%;
			display: block;
			
			& a{
				display: inline-block;
				width: 100%;
				vertical-align: top;
				padding: 1.5rem;
				border-radius: .1rem;
				transition: all .15s;

				& .bloco_servico{
					width: 100%;
					display: block;
					text-align: left;
		
					& .img{
						width: 3rem;
						display: block;
						margin: 0;

						& img{
							transition: all .15s;
							text-align: left;
							width: 100%;
						}
					}

					& h2{
						color: black;
						font-size: 1rem;
						font-weight: 500;
						text-align: left;
					}

					& p{
						padding: 0;
						color: $gray2;
						font-size: .9rem;
						font-weight: 400;
						text-align: left;	
						transition: all .15s;
						margin: 0;
					}
		
					& .underline_x{
						&::after{
							position: absolute;
							content: "";
							width: 1%;
							height: 0;
							background-color: transparent;
							border-radius: 2rem;
							left: 0%;
							bottom: 0;
							transition: all 0.25s ease-in-out;
						}
					}
				}

				&:hover{
					& .img{
						& img{
							transform: scale(1.1);
						}
					}
		
					& .underline_x{
		
						&::after{
							background-color: $colorPrimary;
							height: 100%;
						}
					}
				}
			}
		}
	}
}

@media all and (min-width: 320px) and (max-width: 1023px){
	.max-sz-con{
		max-width: 40rem !important;
		padding-left: 2rem !important;
		padding-right: 1rem !important;
	}
}

@media all and (max-width: 1023px){

	.top_section_imo{
		display: block;
		width: 100%;
		border-bottom: solid 1px lighten($gray4, 10);
		padding: .5rem 0;
		
		& .title_condomi{
			display: block;
			width: 100%;
			
			& h2{
				font-size: 1.1rem;
				font-weight: 500;
				color: $gray1;
				margin: 0;
				text-align: left;
			}
		
			& .under_p{
				text-align: center;
				width: 4.5rem;
				height: 3px;
				margin: 0;
				background-color: $colorPrimary;
			}
		}
		
		& .btn_ver_mais{
			display: block;
			width: fit-content;
			margin-right: 0;
		
			& a{
				width: fit-content !important;
				display: block;
				
				& p{
					color: $gray1;
					font-weight: 600;
					font-size: .8rem;
					transition: all .2s ease-in-out;
					text-align: center;
					margin: 0;
					border: solid 1px $gray4;
					padding: .3rem 1rem;
					border-radius: .2rem;
					background-color: white;
		
					&:hover{
						background-color: $colorTerciary;
						border: solid 1px $colorTerciary;
						color: white;
					}
				}
			}
		}
	}

	.page-wrap{
		display: block;
	}
	.page-wrap .width-50, .main-content, .main-content2{
		width: 100%;
	}
}