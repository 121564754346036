.section_financiamento{
	display: block;
	width: 100%;

	& h1{
		font-size: 2rem;
		font-weight: 600;
		color: $gray1;
		text-align: center;
	}

	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray2;
		text-align: center;
	}

	& .doc-download{
		display: block;
		width: 100%;

		& a{
			display: inline-block;
			vertical-align: middle;
			margin-right: 1rem;
			& p{
				background-color: lighten($gray4, 5);
				color: $colorSecondary;
				padding: 1rem;
				font-size: 1.1rem;
				font-weight: 400;
				transition: all .2s;
				&:hover{
					background-color: $colorSecondary;
					color: white;
				}
			}
		}
	}

	& .bancos_financiamento{
		display: block;
		width: 100%;

		& .logos_bancos{
			display: block;
			width: 100%;
			text-align: center;

			& a{
				text-align: center;
				display: inline-block;
				vertical-align: middle;
				padding: 1rem;
				width: 14.2%;
				& img{
					width: 100%;
					transition: all .2s;
					border: solid 1px $gray4;
				}

				&:hover{
					& img{
						transform: scale(1.1);
						box-shadow: 0 0 15px 0px rgba(black, .2);
					}
				}
			}

		}
	}
}

@media all and (max-width: 1023px){
	.section_financiamento{
		& .bancos_financiamento{
			& .logos_bancos{
				& a{
					text-align: center;
					display: inline-block;
					padding: 1rem;
					width: 50%;
					margin: 0;
					& img{
						width: 100%;
						filter: none;
					}
				}
			}
		}
	}
}